import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import className from 'classnames'
import Head from 'next/head'

const Meta = ({title, description, image, imageExternal}) => {
  return (
    <Head>
      {title && (
        <React.Fragment>
          <title>{title}</title>
          <meta name='og:title' property='og:title' content={title} />
          <meta name='og:image:alt' property='og:image:alt' content={title} />
          <meta name='og:site_name' property='og:site_name' content={title} />
          <meta name='twitter:title' content={title} />
        </React.Fragment>
      )}

      {description && (
        <React.Fragment>
          <meta name='description' content={description} />
          <meta
            name='og:description'
            property='og:description'
            content={description}
          />
          <meta name='twitter:description' content={description} />
        </React.Fragment>
      )}

      {(() => {
        // Somehow nested Fragment doesn't work under <Head />, be aware …
        if (!image) {
          return null
        }

        let _image = `${image}`

        if (!imageExternal) {
          const _host =
            typeof window != 'undefined'
              ? get(window, 'location.origin')
              : 'https://datawow.io'

          _image = `${_host}/${_image}`
        }

        return (
          <React.Fragment>
            <meta name='og:image' property='og:image' content={_image} />
            <meta name='twitter:image' content={_image} />
          </React.Fragment>
        )
      })()}
    </Head>
  )
}

Meta.propTypes = {}

export default Meta
